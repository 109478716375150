.tianjy-spread-sheet-root {
  position: relative;
  display: flex;
  flex-direction: column;
}

.tianjy-spread-sheet-body {
  overflow: hidden;
  flex: 1;
}

.tianjy-spread-sheet-header {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  min-height: 42px;
  align-items: center;
  background-color: var(--fg-color);
  border-top: 1px solid var(--border-color);
  padding-left: var(--padding-xs);
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.tianjy-spread-sheet-add {
  background-color: var(--control-bg);
  padding: 2px;
  margin-left: 4px;
  box-shadow: none;
}

.tianjy-spread-sheet-add:hover {
  background-color: var(--btn-default-hover-bg);
}

.tianjy-spread-sheet-add div {
  padding: 4px 8px;
}

.tianjy-spread-sheet-tabs {
  display: flex;
  flex: 1;
  overflow-x: auto;
}

.tianjy-spread-sheet-tab {
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 18px 10px 15px;
  color: var(--text-muted);
  min-width: -moz-max-content;
  min-width: max-content;
  cursor: pointer;
}
.tianjy-spread-sheet-tab::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 var(--margin-md);
  width: auto;
  border-top: 1px solid transparent;
}
.tianjy-spread-sheet-tab:hover::before {
  border-color: var(--gray-300);
}
.tianjy-spread-sheet-tab.active {
  font-weight: 600;
  color: var(--text-color);
}
.tianjy-spread-sheet-tab.active::before {
  border-color: var(--border-primary);
}
.tianjy-spread-sheet-tab button {
  position: absolute;
  right: -2px;
  display: none;
  padding: 2px;
}
.tianjy-spread-sheet-tab:hover button {
  display: block;
}
.tianjy-spread-sheet-tab input {
  border: none;
  min-width: 50px;
  padding: 0px !important;
}
.tianjy-spread-sheet-tab input:focus {
  outline: none;
  background-color: inherit;
}
.tianjy-spread-sheet-tab input::-moz-placeholder {
  font-style: italic;
  font-weight: normal;
}
.tianjy-spread-sheet-tab input::placeholder {
  font-style: italic;
  font-weight: normal;
}
.tianjy-spread-sheet-tab > :first-child > :last-child {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  width: -moz-max-content;
  width: max-content;
}
.tianjy-spread-sheet-tab > :first-child > :last-child:empty::after {
  content: attr(data-placeholder);
}